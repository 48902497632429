import { useState } from "react";
import axios from "axios";
import "./Search.css";
import Card from "react-bootstrap/Card";
import ConfigData from "../../config.json";
import noImage from "../../assets/images/certificate_default.jpg";
import ClickableDiv from "react-clickable-div";
import QrReader from "modern-react-qr-reader";
import "react-clickable-div/dist/index.css";
const Search = () => {
  const [result, setResult] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [showScanner, setShowScanner] = useState(false);
  const handleOnChange = (event) => {
    setSearchValue(event.target.value);
  };
  const makeApiCall = async (searchInput) => {
    const trimmedSearchInput = searchInput.trim();
    var searchUrl = `${ConfigData.API_URL}/candidate`;
    try {
      const response = await axios.get(searchUrl, {
        params: { query: trimmedSearchInput },
      });
      setCandidates(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = () => {
    setShowScanner(false);
    makeApiCall(searchValue);
  };

  const showQrCodeScanner = () => {
    setCandidates([]);
    setSearchValue("");
    setShowScanner((prevShowScanner) => !prevShowScanner);
  };

  const handleScan = (data) => {
    if (data) {
      setResult(data);
      if (data.includes("http")) {
        // This is new certificate
        window.open(data);
      } else {
        const certInfo = JSON.parse(data);
        if (certInfo && certInfo.CertificateNumber) {
          setShowScanner(false);
          makeApiCall(certInfo.CertificateNumber);
        }
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      makeApiCall(e.target.value);
    }
  };

  return (
    <div>
      {/* <div className="serach-container">
        <input name="text" type="text" placeholder="Please enter Certificate number"
          onChange={event => handleOnChange(event)}
          value={searchValue}
        />
        <button onClick={handleSearch} className="mt-3">Search</button>
      </div> */}

      <div className="main ">
        {/* <div className="form-group has-search">
    <span className="fa fa-search form-control-feedback"></span>
    <input type="text" class="form-control" placeholder="Search"/>
  </div> */}

        <div className="input-group">
          <input
            type="text"
            onKeyDown={(event) => keyPress(event)}
            className="form-control search-input"
            onChange={(event) => handleOnChange(event)}
            value={searchValue}
            placeholder="Please enter Certificate number to search."
          />
          <div className="input-group-append">
            <button
              onClick={handleSearch}
              className="btn btn-secondary mr-1"
              type="button"
            >
              <i className="fa fa-search"></i>
            </button>
            <button
              onClick={showQrCodeScanner}
              className="btn btn-secondary ml-1"
              type="button"
            >
              <i className="fa fa-qrcode"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        {showScanner && (
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            facingMode={"environment"}
            style={{ width: "50%" }}
          />
        )}
      </div>

      <div>
        {candidates.length != 0 ? (
          <div className="container d-flex flex-wrap ">
            {candidates.map((candidate, index) => (
              <div key={index}>
                {/* <Card style={{ width: '18rem', margin: "2rem" }}>
                  <Card.Body>
                    <p>Name : <span className="data">{candidate.name} </span></p>
                    <p>Course enrolled : <span className="data">{candidate.subject} </span></p>
                    <p>Certificate Number : <span className="data">{candidate.certNo} </span></p>
                    <p>Enrollment Date : <span className="data">{candidate.startDate} </span></p>
                    <p>Completion Date :   <span className="data"> {candidate.endDate}</span></p>
                    <a href={candidate.imageUrl}>Download Certificate </a>
                  </Card.Body>
                </Card> */}
                <div className="card" style={{ width: 500 }}>
                  <div className="row no-gutters">
                    <div
                      onClick={() => {
                        window
                          .open(
                            `${candidate.imageUrl}${ConfigData.AZURE_BLOB_SAS_URL}`,
                            "_blank"
                          )
                          .focus();
                      }}
                      className="col-sm-5 my-awesome-div transparent-button"
                    >
                      {/* <img className="card-img" src={noImage} alt="Suresh Dasari Card"/> */}

                      <object
                        data={`${candidate.imageUrl}${ConfigData.AZURE_BLOB_SAS_URL}`}
                        width="100%"
                        height="202px"
                        style={{
                          pointerEvents: "none",
                          border: "none",
                        }}
                        type="application/pdf"
                      >
                        {/* <p>
                          Please download the certificate from{" "}
                          <a
                            href={`${candidate.imageUrl}${ConfigData.AZURE_BLOB_SAS_URL}`}
                          >
                            here
                          </a>
                        </p> */}

                        <button
                          style={{
                            color: "white",
                            background: "#0076be",
                            width: "auto",
                            padding: "2px 4px",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                            border: "none",
                            cursor: "pointer",
                            display: "inline-block",
                          }}
                          onClick={() => {
                            window
                              .open(
                                `${candidate.imageUrl}${ConfigData.AZURE_BLOB_SAS_URL}`,
                                "_blank"
                              )
                              .focus();
                          }}
                        >
                          Get Certificate
                        </button>
                      </object>
                    </div>
                    <div className="col-sm-7">
                      <div className="card-body pt-0">
                        <h5 className="card-title">{candidate.name}</h5>
                        <p className="card-text">
                          Student ID: {candidate.studentId}
                        </p>
                        <p className="card-text">
                          Certificate Number: {candidate.certNo}
                        </p>
                        <p className="card-text">
                          {" "}
                          Program Name: {candidate.subject}
                        </p>
                        <p className="card-text">
                          {" "}
                          Date of completion: {candidate.startDate}
                        </p>
                        <p className="card-text">
                          {" "}
                          Date of certificate issued: {candidate.endDate}
                        </p>
                        {/* <a href="#" className="btn btn-primary">View Profile</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : !showScanner ? (
          <p className="no-resultp">No result found</p>
        ) : null}
      </div>
    </div>
  );
};

export default Search;
