import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Search from "./Screens/Search/Search";
import DownloadCertificate from "./Screens/DownloadCertificate/DownloadCertificate";
import "./App.css";

const App = () => {
  return (
    <Router>
      <Switch>
        {/* Default route for the Search component */}
        <Route exact path="/" component={Search} />

        {/* Route for downloading certificates */}
        <Route path="/certificates/:certId" component={DownloadCertificate} />

        {/* Catch-all route for undefined paths */}
        <Route path="*" render={() => <h2>404 Not Found</h2>} />
      </Switch>
    </Router>
  );
};

export default App;
